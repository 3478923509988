






import Vue from 'vue';
import ContactForm from '@/components/ContactForm.vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: { ContactForm }
})
export default class Contact extends Vue {}
