





















































import Vue from 'vue';
import router from '@/router';
import { Component } from 'vue-property-decorator';

@Component({})
export default class NavBar extends Vue {
  
  goTo(value: string) {
    router.push({ name: value });
    this.$vuetify.goTo(0);
  }
}
