































































































































import Vue from 'vue';
import router from '@/router';
import { Component } from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';

@Component({
  components: { 
    VueRecaptcha 
  }
})
export default class ContactForm extends Vue {
  $refs!: {
    form: HTMLFormElement,
    recaptcha: VueRecaptcha
  }

  valid = true;
  submitted = false;
  success = false;
  error = false;
  message = '';
  contact = {
    salutation: null,
    firstname: '',
    lastname: '',
    companyname: '',
    email: '',
    country: '',
    subject: '',
    message: '',
    acceptPrivacyPolicy: false,
    recaptchaToken: '',
  }
  emailRules= [
    (v: string) => !!v || 'Email is required',
    (v: string) => /.+@.+\..+/.test(v) || 'Email must be valid',
  ]
  firstNameRules = [
    (v: string) => !!v || 'First Name is required',
    (v: string) => (v && v.length >= 3) || 'First Name must be at least 3 characters',
  ]
  lastNameRules = [
    (v: string) => !!v || 'Last Name is required',
    (v: string) => (v && v.length >= 3) || 'Last Name must be at least 3 characters',
  ]
  salutationOptions = [
    'Mr.',
    'Mrs.',
    'Ms.'
  ]

  submit() {
    if (this.$refs.form.validate()) {
      this.submitted = true;
      this.$refs.recaptcha.execute();
    }
  }

  onCaptchaVerified(recaptchaToken: string) {
    this.contact.recaptchaToken = recaptchaToken;
    this.$refs.recaptcha.reset();
    const url = '/sendEmail';
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.contact)
    }).then((response) => {
      if(response.ok) {
        this.message = 'Thanks for contacting us, we will reply to you as soon as possible.';
        this.success = true;
        this.$refs.form.reset();
      } else {
        response.json().then((object) => {
          this.message = object.message ? object.message : 'An error occured while sending your email, please try again later.';
        })
        this.error = true;  
      }
      this.submitted = false;
    }).catch((err) => {
      this.message = err.message ? err.message : 'An error occured while sending your email, please try again later.';
      this.error = true;
      this.submitted = false;
    })
  }

  onCaptchaExpired() {
    this.$refs.recaptcha.reset();
  }
}
