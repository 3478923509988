






import Vue from 'vue';
import MainContent from '@/components/MainContent.vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: { MainContent }
})
export default class Home extends Vue {}
