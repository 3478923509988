























import Vue from 'vue';
import router from '@/router';
import { Component } from 'vue-property-decorator';

@Component({})
export default class NavBar extends Vue {

  fab = false;
  
  onScroll(e: any) {
    if (typeof window === 'undefined') return;
    const top = window.pageYOffset ||   e.target.scrollTop || 0;
    this.fab = top > 20;
  }

  toTop() {
    this.$vuetify.goTo(0);
  }
}
