










import Vue from 'vue';
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: { 
    Footer,
    NavBar
  }
})
export default class App extends Vue {
  
  constructor() {
    super();
  }
}
